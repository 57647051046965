import * as React from "react"
import { navigate } from "gatsby"

const ContactListFilterAndSearch = ({ searchTerm, setSearchTerm }) => {
  return (
    <div className="input-group filterDropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="bi bi-bullseye" />
      </button>

      <ul className="dropdown-menu">
        <li>
          <span className="dropdown-item-text">== Saved Filtered Views ==</span>
        </li>
        <li>
          <a className="dropdown-item createNewFilterView" href="#">
            <i className="bi bi-plus" /> Create New Filtered View
          </a>
        </li>
      </ul>
      <input
        type="search"
        className="form-control tableSearch"
        placeholder="Search"
        defaultValue={searchTerm}
        onKeyUp={e => {
          setSearchTerm(e.target.value)
          if (e.target.value === "") {
            window.location.href = `/contacts`
          } else {
            const queryParams = new URLSearchParams(window.location.search)
            queryParams.set("search", e.target.value)
            const newUrl = `${window.location.pathname}?${queryParams.toString()}`
            window.history.replaceState({}, "", newUrl)
          }
        }}
      />
    </div>
  )
}

export default ContactListFilterAndSearch
