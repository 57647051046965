import * as React from "react"

import PrivateRoute from "../../components/privateRoute"
import List from "../../components/Contacts/Listing/list"
import ReduxWrapper from "../../redux/reduxWrapper"

const Contacts = props => {
  return <PrivateRoute component={List} location={props.location} />
}

const WrappedPage = props => <ReduxWrapper element={<Contacts {...props} />} />
export default WrappedPage

export const Head = () => <title>Contacts List</title>
