import * as React from "react"
import { useRef, useState } from "react"
import clsx from "clsx"
import Layout from "../../Layout/layout"
import ContactLazyLoad from "./contactLazyLoad"

import "../../../css/jquery.dataTables.min.css"
import "../contacts.css"
import ContactListFilterAndSearch from "./contactListFilterAndSearch"
import { Link } from "gatsby"
import ContactTable from "./contactTable"
import NewGroupModal from "../Groups/Listing/newGroupModal"
import Seo from "../../seo"
import { onGrabData } from "./contactListHelper"

const List = props => {
  const triggerRef = useRef(null)
  const [deleteContactRow, setDeleteContactRow] = useState(null)

  const searchParams = new URLSearchParams(window.location.search)
  const searchData = searchParams.get("search")
  const [searchTerm, setSearchTerm] = useState(searchData)

  const { data, loading } = ContactLazyLoad({
    triggerRef,
    onGrabData,
    deleteContactRow,
    setDeleteContactRow,
    searchTerm,
    setSearchTerm,
  })

  return (
    <Layout>
      <Seo title="Contacts" />
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <div className="col" id="page-urls">
            <div className="row tabularMenuContainer">
              <div className="col">
                <ContactListFilterAndSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
              </div>
              <div className="col-sm-8">
                <div className="float-end">
                  <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                    <li className="nav-item">
                      <div className="btn-group btnAddNewDropdown" role="group">
                        <button
                          type="button"
                          className="btn btn-primary dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-plus-lg" />
                          <span className="d-none d-md-inline">Add New</span>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <Link to={"add"} className="dropdown-item">
                              <i className="bi bi-person-fill" /> Contact
                            </Link>
                          </li>
                          <li>
                            <NewGroupModal />
                          </li>
                          <li>
                            <Link to={"/csv-import"} className="dropdown-item">
                              <i className="bi bi-table" /> Upload CSV
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="contacts-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contacts-tab-pane"
                        role="tab"
                        aria-controls="contacts-tab-pane"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" title="Contacts">
                          <i className="bi bi-person-fill" />
                        </span>
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <Link to={"/contacts/groups"} className="nav-link" title="Groups & Audiences">
                        <i className="bi bi-people-fill" />
                      </Link>
                    </li>
                    <li className="nav-item nav-item-trash" role="presentation">
                      <a
                        className="nav-link"
                        id="contacts-trash-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contacts-trash-pane"
                        role="tab"
                        aria-controls="contacts-trash-pane"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" title="Trash">
                          <i className="bi bi-trash-fill" />
                        </span>
                      </a>
                    </li>
                  </ul>

                  <ul className="nav nav-tabs swiftCloudTabs mobileTabs d-md-none d-flex mb-1" role="tablist">
                    <li className="nav-item">
                      <div className="btn-group btnAddNewDropdown" role="group">
                        <button
                          type="button"
                          className="btn btn-primary dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-plus-lg" />
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <Link className="dropdown-item" to={"add"}>
                              <i className="bi bi-person-fill" /> Contact
                            </Link>
                          </li>
                          <NewGroupModal />
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-table" /> Upload CSV
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="btn-group btnDriveView" role="group">
                        <button
                          type="button"
                          className="btn btn-default dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-person-fill" />
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              className="dropdown-item"
                              data-bs-toggle="tab"
                              data-bs-target="#contacts-tab-pane"
                              role="tab"
                              aria-controls="contacts-tab-pane"
                              aria-selected="false"
                            >
                              <i className="bi bi-person-fill" /> Contacts
                            </a>
                          </li>
                          <li>
                            <Link to={"/contacts/groups"} className="dropdown-item">
                              <i className="bi bi-people-fill" /> Groups & Audiences
                            </Link>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              data-bs-toggle="tab"
                              data-bs-target="#contacts-trash-pane"
                              role="tab"
                              aria-controls="contacts-trash-pane"
                              aria-selected="false"
                            >
                              <i className="bi bi-trash-fill" /> Trash
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 tableFadeOut">
                <div className="tab-content" id="contactListTabs">
                  <div
                    className="tab-pane fade show active"
                    id="contacts-tab-pane"
                    role="tabpanel"
                    aria-labelledby="contacts-tab"
                    tabIndex="0"
                  >
                    <ContactTable data={data} setDeleteContactRow={setDeleteContactRow} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contacts-trash-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                    tabIndex="0"
                  >
                    <table id="tblTrashContacts" className="table dataTable" cellSpacing="0" width="100%">
                      <thead>
                        <tr>
                          <th width="5%">&nbsp;</th>
                          <th width="70%">
                            <div className="btn-group btnAddNewDropdown filterContactDropdown" role="group">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="bi bi-bullseye"></i>{" "}
                                <span className="d-none d-md-inline">Everyone</span>
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-people-fill"></i> Local Friends
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-person-fill"></i> People
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-building"></i> Companies
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-person-check-fill"></i> Following
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-people-fill"></i> Tribes
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </th>
                          <th width="20%" className="d-none d-md-table-cell">
                            Last Activity
                          </th>
                          <th width="5%" className="text-end d-none d-lg-table-cell"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="tableColChkDel">
                            <div className="tableColChkDelBox">
                              <input type="checkbox" name="fileid[]" id="TC_ID1" className="css-checkbox" />
                              <label htmlFor="TC_ID1" className="css-checkbox-label"></label>
                              <span className="tableColChkSpan btn-secondary">
                                <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/Rosa.jpg`} />
                              </span>
                            </div>
                          </td>
                          <td className="nowrap">
                            <a href="contact_detail.html">Rosa</a>
                            <span className="contactListCompany">SwiftCloud</span>
                            <div className="contactListQuickTools">
                              <a
                                className="contactEmail"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="rosa@domain.com"
                              >
                                <i className="bi bi-envelope-fill"></i>
                              </a>
                              <a
                                className="contactPhoneSMS"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="SMS to 9876543210"
                              >
                                <i className="bi bi-phone-fill"></i>
                              </a>
                              <a
                                className="contactPhoneCall"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="Call to 9876543210"
                              >
                                <i className="bi bi-telephone-fill"></i>
                              </a>
                              <a
                                className="contactAddress"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="New street"
                              >
                                <i className="bi bi-geo-alt-fill"></i>
                              </a>
                            </div>
                            <div className="SwiftCloudTableTags">
                              <ul>
                                <li>
                                  <a href="#" data-tagname="Support">
                                    Support
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <time className="timeago" dateTime="2022-05-01"></time>
                          </td>
                          <td className="text-end tableColAction d-none d-lg-table-cell">
                            <a
                              href="#"
                              className="btn btn-delete"
                              data-bs-toggle="tooltip"
                              data-placement="bottom"
                              data-animation="false"
                              title="Delete"
                            >
                              <i className="bi bi-trash-fill"></i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="tableColChkDel">
                            <div className="tableColChkDelBox">
                              <input type="checkbox" name="fileid[]" id="TC_ID2" className="css-checkbox" />
                              <label htmlFor="TC_ID2" className="css-checkbox-label"></label>
                              <span className="tableColChkSpan btn-secondary">
                                <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/susan.jpg`} />
                              </span>
                            </div>
                          </td>
                          <td className="nowrap">
                            <a href="contact_detail.html">Susan</a>
                            <div className="contactListQuickTools">
                              <a
                                className="contactPhoneCall"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="Call to 9876543210"
                              >
                                <i className="bi bi-telephone-fill"></i>
                              </a>
                              <a
                                className="contactAddress"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="New street"
                              >
                                <i className="bi bi-geo-alt-fill"></i>
                              </a>
                            </div>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <time className="timeago" dateTime="2022-05-15"></time>
                          </td>
                          <td className="text-end tableColAction d-none d-lg-table-cell">
                            <a
                              href="#"
                              className="btn btn-delete"
                              data-bs-toggle="tooltip"
                              data-placement="bottom"
                              data-animation="false"
                              title="Delete"
                            >
                              <i className="bi bi-trash-fill"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={triggerRef} className={clsx("trigger", { visible: loading })} />
      </div>
    </Layout>
  )
}

export default List
